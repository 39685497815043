import React from "react";
// import { withPrefix } from 'gatsby'

export default class extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      active: props.defaultState
    }
  }

  handlePress = (event) => {
    this.setState((state, props) => {
      return {active: !state.active};
    });

    this.props.onChangeState(this.state.active);
  }
  render() {
    return (
      <div className={`hamburger block lg:hidden menuFive ${this.state.active ? "clickMenuFive" : ""}`}
            onClick={this.handlePress} 
            onKeyPress={this.handlePress} 
            role="button" tabIndex="0">
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }
}

