import React from 'react'
import Logo from './logo'
import Hamburger from './hamburger'
import { Link } from 'gatsby'
import posed from 'react-pose';

function detectmob() {
  if(typeof(navigator) !== "undefined"){
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
      return true;
    }
    else {
      return false;
    }
  }
 }

const Box = posed.div({
  showNavigation: { height: '200px' },
  hideNavigation: { height: (detectmob() ? '0px' : 'auto') }
});

export default class extends React.Component {
  state = {
    active: false
  }

  handlePress = (isActive) => {
    this.setState((state, props) => {
      return {active: !state.active};
    });
  }
  render() {
    return (
      <div className="flex justify-between flex-col lg:flex-row flex-wrap py-4 px-0 lg:px-4">
        <Logo />
        <Hamburger defaultState={this.state.active} onChangeState={this.handlePress}/>
        <Box
            className="box flex"
            pose={this.state.active ? 'showNavigation' : 'hideNavigation'}
          >
          <nav 
            className={`w-full block flex flex-grow lg:flex lg:items-center justify-end bg-black lg:bg-transparent ${this.state.active ? "" : "hidden1"}`}>
            <ul className="list-reset flex flex-col lg:flex-row float-right w-full lg:w-auto">
              <li className="mx-4 py-2 lg:py-0"><Link className="text-lg text-black no-underline navigation-links text-white lg:text-black text-color" activeClassName="active" to="/">Home</Link></li>
              <li className="mx-4 py-2 lg:py-0"><Link className="text-lg text-black no-underline navigation-links text-white lg:text-black text-color" activeClassName="active" to="/about/">About</Link></li>
              <li className="mx-4 py-2 lg:py-0"><Link className="text-lg text-black no-underline navigation-links text-white lg:text-black text-color" activeClassName="active" to="/blog/">Blog</Link></li>
              {/* <li className="mx-4 py-2 lg:py-0"><Link className="text-lg text-black no-underline navigation-links text-white lg:text-black" activeClassName="active" to="/photography/">Photography</Link></li> */}
              <li className="mx-4 py-2 lg:py-0"><Link className="text-lg text-black no-underline navigation-links text-white lg:text-black text-color" activeClassName="active" to="/contact/">Contact</Link></li>
            </ul>
          </nav>
        </Box>
      </div>
    );
  }
}


