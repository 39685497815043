import React from "react";
// import { withPrefix } from 'gatsby'
import logoImg from "./logo.svg";
import { Link } from 'gatsby'
export default () => (
  <div className="mx-4 my-4">
    <Link to="/">
      <img
        src={logoImg}
        alt="g"
        className="logo"
      />
    </Link>
  </div>
);
