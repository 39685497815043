import React from "react";

export default class extends React.Component {
  render() {
    return (
      <footer className="main-wrapper">
        <div className="py-4 lg:py-0 flex flex-row sm:flex-row lg:flex-row justify-between mx-8">
          <p className="w-1/2 sm:w-1/2 lg:w-2/5 text-color">
            Made with love using{" "}
            <span className="react">&lt;react&nbsp;/&gt;</span>
          </p>
          <ul className="footer__social list-reset flex justify-around w-1/2 sm:w-1/4 lg:w-1/6">
              <li className="footer__social__list mx-2">
                <a target="_blank" rel="noopener noreferrer" className="footer__social__link" href="https://twitter.com/igauravity">
                  <img
                    className="footer__social__icon"
                    src={require(`./twitter.svg`)}
                    style={{height: "18px"}}
                    alt="twitter"
                  />
                </a>
              </li>

              <li className="footer__social__list mx-2">
                <a target="_blank" rel="noopener noreferrer" className="footer__social__link" href="https://plus.google.com/111648100843323365754">
                  <img
                    className="footer__social__icon"
                    src={require(`./googleplus.svg`)}
                    alt="googleplus"
                    style={{height: "18px"}}
                  />
                </a>
              </li>
              <li className="footer__social__list mx-2">
                <a target="_blank" rel="noopener noreferrer" className="footer__social__link" href="https://www.reddit.com/user/gauravity_/">
                  <img
                    className="footer__social__icon"
                    src={require(`./redit.svg`)}
                    alt="redit"
                    style={{height: "18px"}}
                  />
                </a>
              </li>
              <li className="footer__social__list mx-2">
                <a target="_blank" rel="noopener noreferrer" className="footer__social__link" href="https://stackoverflow.com/users/113664/gaurav-jassal">
                  <img
                    className="footer__social__icon"
                    src={require(`./stackoverflow.svg`)}
                    alt="stackoverflow"
                    style={{height: "18px"}}
                  />
                </a>
              </li>
          </ul>
        </div>
      </footer>
    );
  }
}
