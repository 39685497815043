import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import '../styles/layout.scss'
import "../styles/index.css";

require("prismjs/plugins/line-numbers/prism-line-numbers.css")

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title,
            tagLine
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Lead Full Stack Developer - React and React Native Specialist, London, UK.' },
            { name: 'keywords', content: data.site.siteMetadata.tagLine },
          ]}
        >
          <html lang="en" />
          <body className="body-bg" />
        </Helmet>
        <div className="main-wrapper">
          <div className="container-wrapper my-0 my-0 lg:my-4 lg:mx-4 overflow-hidden body-inner-bg">
            <Header siteTitle={data.site.siteMetadata.title} />
            {children}
          </div>
        </div>
        <Footer/>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
